<template>
	<div class="input-select" :class="{ 'input-style-light': lightTheme }">
		<label
			class="block border border-brand input-with-arrow w-full"
			:class="[{ 'input-style-light': lightTheme }]"
		>
			<div>
				<p
					class="mb-0 px-3 text-left smaller leading-1500 text-grey-600 tracking-wide mt-0250"
				>
					{{ labelText }}
				</p>
			</div>
			<div>
				<input
					class="input w-full appearance-none px-3 pt-0 pb-2 border-none"
					:class="{ 'input-style-light': lightTheme }"
					type="text"
					v-model="value"
					:placeholder="placeholder"
					:required="required"
					:disabled="disabled"
					@keydown="inputKeyDown"
					ref="input"
				/>
			</div>
		</label>
		<transition name="vt-fade">
			<div v-if="open" class="input-select-list">
				<div ref="list" class="input-select-list-inner">
					<button
						v-for="(item, i) in items"
						:class="{ focused: focusIndex === i }"
						@click.prevent="select(item)"
						@mouseenter="focusIndex = i"
						@focus="focusIndex = i"
						@blur="itemBlur(i)"
						class="input-select-item"
						type="button"
						v-text="field ? item[field] : item"
						:key="i"
					/>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Dropdown from '@/mixins/Dropdown';

export default {
	mixins: [Dropdown],

	props: {
		selectFirstWhenRequired: {
			type: Boolean,
			default: false,
		},
		labelText: {
			type: String,
			default: '',
		},
	},

	computed: {
		hasValue() {
			return this.value;
		},
	},

	methods: {
		select(item) {
			this.open = false;
			this.$emit('select', item);
		},

		enterPressed() {
			this.select(this.items[this.focusIndex]);
		},
	},

	mounted() {
		if (
			this.selectFirstWhenRequired &&
			this.required &&
			this.items.length > 0
		) {
			this.select(this.items[0]);
		}
	},
};
</script>

<style scoped>
.input,
.input-date input {
	position: relative;
	display: inline-block;
	border-width: 1px;
	border-color: #d2c298;
	background-color: transparent;
	font-weight: 400;
	font-size: 16px;
	text-align: left;
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	/*padding: .7rem 1rem;*/
	outline: none;
}
.input-with-arrow:after,
.input-select .input:after {
	border-top: 0.4rem solid #d2c298 !important;
	content: '';
	position: absolute;
	top: 50%;
	right: 1rem;
	margin-top: -0.2rem;
	border-top: 0.4rem solid white;
	border-left: 0.4rem solid transparent;
	border-right: 0.4rem solid transparent;
	pointer-events: none;
}

.border-none {
	border-style: none;
}

.input-select {
	position: relative;
}

.input-select .input,
.input-select .input-textarea,
.input-select .input-date input,
.input-date .input-select input {
	cursor: pointer;
}

.input-select .input:disabled,
.input-select .input-textarea:disabled,
.input-select .input-date input:disabled,
.input-date .input-select input:disabled {
	cursor: not-allowed;
}

.input-select-item {
	padding: 10px 30px 10px 30px;
}

.input-select label.input,
.input-select label.input-textarea {
	cursor: pointer;
	color: rgba(255, 255, 255, 0.5);
}

.input-select label.input.input-has-value,
.input-select label.input-has-value.input-textarea {
	color: white;
}

.input-select .input-select-list {
	width: 100%;
	position: absolute;
	z-index: 10;
	background-color: #132032;
	-webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	top: -webkit-calc(100% - 1px);
	top: calc(100% - 1px);
	border: 1px solid #d2c298;
}

.input-select .input-select-list-inner {
	background-color: #132032;
	max-height: 14rem;
	border-top: 1px solid #2f3848;
	overflow: auto;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.input-select .input-select-list-inner::-webkit-scrollbar {
	width: 10px;
}

.input-select .input-select-list-inner::-webkit-scrollbar-track {
	-webkit-border-radius: 0;
	border-radius: 0;
	background: rgba(255, 255, 255, 0.1);
}

.input-select .input-select-list-inner::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: white;
}

.input-select .input-select-item {
	display: block;
	width: 100%;
	font-weight: 400;
	border-top-width: 1px;
	border-color: #2f3848;
	font-size: 16px;
	color: #fff;
	text-align: left;
	/*padding: .7rem 1rem;*/
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-decoration: none;
	background-color: transparent;
}

.input-select .input-select-item:first-child {
	border-top: 0;
}

.input-select .input-select-item.focused {
	background-color: #2f3848;
	cursor: pointer;
}

.input-select .input-select-item.no-results {
	font-family: mrs-eaves, serif;
	color: #fff;
	font-style: italic;
}

.input-select.input-style-light label.input,
.input-select.input-style-light label.input-textarea {
	color: #9599a0;
}

.input-select.input-style-light label.input.input-has-value,
.input-select.input-style-light label.input-has-value.input-textarea,
.input-select.input-style-light input.input,
.input-select.input-style-light input.input-textarea,
.input-select.input-style-light .input-date input,
.input-date .input-select.input-style-light input {
	color: #132032;
}

.input-select.input-style-light .input-select-list-inner {
	background-color: #fff;
	border-top: 1px solid #f1f3f5;
}

.input-select.input-style-light
	.input-select-list-inner::-webkit-scrollbar-track {
	-webkit-border-radius: 0;
	border-radius: 0;
	background: rgba(221, 221, 221, 0.5);
}

.input-select.input-style-light
	.input-select-list-inner::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #ccc;
}

.input-select.input-style-light .input-select-item {
	border-color: #f1f3f5;
	color: #132032;
}

.input-select.input-style-light .input-select-item.focused {
	background-color: #f1f3f5;
}

.input-select.input-style-light .input-select-item.no-results {
	color: #9599a0;
}
</style>
