<template>
	<div>
		<div class="flex fixed pin z-top">
			<div
				ref="cover"
				class="absolute pin z-10 bg-black opacity-80"
				@click="close"
			/>

			<div
				ref="content"
				class="self-center max-w-30 min-w-18 relative z-20 m-auto"
			>
				<div
					class="relative p-20 bg-white rounded-0250 shadow-lg text-center"
				>
					<div class="close-button" @click="close">
						<svg
							class="fill-current text-black"
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
						>
							<path
								d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
							></path>
						</svg>
					</div>
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { TweenMax } from 'gsap';

export default {
	mounted() {
		this.checkForCloseButton();
		const { cover } = this.$refs;
		const { content } = this.$refs;

		TweenMax.from(cover, 0.25, {
			opacity: 0,
		});

		TweenMax.from(content, 0.25, {
			delay: 0.25,
			opacity: 0,
			scale: 0.75,
		});
	},

	methods: {
		checkForCloseButton() {
			const closeButtons = this.$el.getElementsByClassName(
				'message-popup-close'
			);
			for (let i = 0; i < closeButtons.length; i += 1) {
				closeButtons[i].onclick = () => this.close();
			}
		},

		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
.pin {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.bg-black {
	background-color: #132632;
}
.max-w-30 {
	max-width: 30rem;
}
.min-w-18 {
	min-width: 18px;
}
.text-black {
	color: #132632;
}
.close-button {
	@apply absolute;
	cursor: pointer;
	top: 1rem;
	right: 1rem;
	width: 1rem;
	height: 1rem;
}
.message-popup-close {
	border-width: 0px;
	&:hover {
		text-decoration: underline;
		color: #776229;
		background: #d1c299;
	}
}

.flex {
	display: flex;
}
.fixed {
	/* Add styles for fixed class */
}
.z-top {
	z-index: 1000;
	/* Add styles for z-100 class */
}
.absolute {
	/* Add styles for absolute class */
}
.opacity-80 {
	/* Add styles for opacity-80 class */
}
.self-center {
	/* Add styles for self-center class */
}
.relative {
	/* Add styles for relative class */
}
.m-auto {
	/* Add styles for m-auto class */
}
.p-2000 {
	/* Add styles for p-2000 class */
}
.bg-white {
	/* Add styles for bg-white class */
}
.rounded-0250 {
	/* Add styles for rounded-0250 class */
}
.shadow-lg {
	/* Add styles for shadow-lg class */
}
.text-center {
	/* Add styles for text-center class */
}
.fill-current {
	/* Add styles for fill-current class */
}
</style>
