<template>
	<div class="w-full py-1.5 mb-5 font-parisine font-normal text-brand">
		<label
			class="mb-10"
			:class="labelClass"
			v-text="`Birthday${required ? '*:' : ':'}`"
		/>
		<div class="h-[60px] pt-5" :class="inputContainerClass">
			<input
				v-model="day"
				class="px-3 border border-brand bg-transparent placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
				:class="[dayWidth]"
				type="number"
				:placeholder="required ? 'DD*' : 'DD'"
				min="1"
				max="31"
				:required="required"
				autocomplete="bday-day"
			/>
			<input
				v-model="month"
				class="px-3 border border-brand bg-transparent placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
				:class="[monthWidth]"
				type="number"
				:placeholder="required ? 'MM*' : 'MM'"
				min="1"
				max="12"
				:required="required"
				autocomplete="bday-month"
			/>
			<input
				v-model="year"
				class="px-3 border border-brand bg-transparent placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
				:class="[yearWidth]"
				type="number"
				:placeholder="required ? 'YYYY*' : 'YYYY'"
				min="1920"
				:max="currentYear"
				:required="required"
				autocomplete="bday-year"
			/>
		</div>

		<p v-if="!valid" class="smaller text-error">
			Please enter a valid date
		</p>
	</div>
</template>

<script type="text/javascript">
import { DateTime } from 'luxon';

export default {
	props: {
		dark: {
			type: Boolean,
			default: false,
		},

		inputContainerClass: {
			type: String,
			default: 'flex gap-4',
		},

		labelClass: {
			type: String,
			default: '',
		},

		dayWidth: {
			type: String,
			default: 'w-full',
		},

		monthWidth: {
			type: String,
			default: 'w-full',
		},

		yearWidth: {
			type: String,
			default: 'w-full',
		},

		value: {
			type: Date,
		},
		required: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			day: this.value
				? new Date(this.value).replace(/-/g, '/').getDate()
				: '',
			month: this.value
				? new Date(this.value).replace(/-/g, '/').getMonth() + 1
				: '',
			year: this.value
				? new Date(this.value).replace(/-/g, '/').getFullYear()
				: '',
			currentYear: new Date().getFullYear(),
		};
	},

	computed: {
		birthday() {
			return this.day !== '' && this.month !== '' && this.year !== ''
				? `${this.day}-${this.month}-${this.year}`
				: null;
		},

		valid() {
			if (this.birthday) {
				// we have inputs for each date component, but is this a valid date?
				const dayTest = this.day.toString().padStart(2, '0');
				const monthTest = this.month.toString().padStart(2, '0');
				const luxonDate = DateTime.fromFormat(
					`${this.year}-${monthTest}-${dayTest}`,
					'yyyy-MM-dd',
					{ zone: 'utc' }
				);

				return luxonDate.isValid;
			}

			// valid if all fields are empty (since it's optional) but not valid if only 1 or 2 fields have values
			return this.day === '' && this.month === '' && this.year === '';
		},
	},

	watch: {
		birthday() {
			if (this.valid) {
				const birthday = new Date(
					`${this.year}-${this.month}-${this.day}`.replace(/-/g, '/')
				);
				this.$emit('updateBirthday', birthday);
			}
		},

		value() {
			let validDate = false;

			if (this.value !== null) {
				const dateValue = DateTime.fromJSDate(this.value);

				if (dateValue.isValid) {
					validDate = true;

					this.day = dateValue.day;
					this.month = dateValue.month;
					this.year = dateValue.year;
				}
			}

			if (!validDate) {
				this.day = '';
				this.month = '';
				this.year = '';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.text-error {
	color: red;
}
</style>
